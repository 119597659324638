import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { GrLinkedinOption } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import {} from "react-feather";
import Logo from "./../../component/Logo";
import axios from "axios";
import { baseApiUrl } from "src/constants";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const [socialLink, setSocialLink] = useState({});
  const [footerContent, setFooterContent] = useState({});
  function getSocialLink() {
    axios({
      method: "GET",
      url: `${baseApiUrl}/static-content/follow-us-link/`,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => setSocialLink(res?.data?.data[0]))

      .catch((err) => console.log(err?.response?.data?.response_message));
  }

  function getFooterContent() {
    axios({
      method: "GET",
      url: `${baseApiUrl}/static-content/footer-contant/`,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => setFooterContent(res?.data?.data[0]))
      .catch((err) => console.log(err?.response?.data?.response_message));
  }
  useEffect(
    () => {
      getSocialLink();
      getFooterContent();
    },
    {},
    {}
  );
  return (
    <>
      <Box className={`${classes.footerSection} mainbg2`}>
        <Box style={{ margin: "20px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ color: "white" }}
              justify="space-around"
              spacing={1}
            >
              <Grid item xs={12} sm={4} md={4}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  alignItems="flex-start"
                >
                  <Grid item>
                    {" "}
                    <Logo width="200" />{" "}
                  </Grid>
                  <Grid item>
                    {" "}
                    <Box mt={2}>
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: "normal",
                          width: "75%",
                        }}
                      >
                        {footerContent && footerContent?.description}
                      </Typography>{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Other
                    </Typography>

                    <List className="listingFooter">
                      <ListItem>
                        <Link to="/pricing">Pricing</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/about">About Us</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/contactus">Contact Us</Link>
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid item xs={8} sm={6} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Legal/Contact
                    </Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link to="/termsconditions">Terms & Conditions</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="privacy-policy">Privacy Policy</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="/cookies-policy">Cookie Policy</Link>
                      </ListItem>
                      <ListItem>
                        <Link to="">support@scoremycv.com</Link>
                      </ListItem>
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Follow Us
                    </Typography>
                    <Box mt={2} className="sociaLinks">
                      {socialLink && (
                        <List>
                          <ListItem>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={socialLink?.facebook}
                            >
                              <FaFacebookF />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={socialLink?.twitter}
                            >
                              <FaTwitter />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={socialLink?.linkedin}
                            >
                              <GrLinkedinOption />
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={socialLink?.instagram}
                            >
                              <FaInstagram />
                            </a>
                          </ListItem>
                        </List>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="lowerFooter">
                  <Typography
                    style={{ fontSize: "12px", fontWeight: "normal" }}
                  >
                    Copyright © 2021 . All Rights Reserved.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
