export const rezorpayKey = "rzp_live_TiPS1SBmnwwlm1"; // client production key
// export const rezorpayKey = "rzp_test_SCS0aAcYYIOE6a"; //client test key
// export const baseApiUrl = "https://zip.scoremycv.com/mv1/api";
// export const mediaBaseUrl = "https://zip.scoremycv.com";

// export const baseApiUrl = "http://localhost:8000/mv1/api";
// export const mediaBaseUrl = "http://localhost:8000";

export const baseApiUrl = "https://nlp-python.mobiloitte.com/mv1/api";
export const mediaBaseUrl = "https://nlp-python.mobiloitte.com/";
