/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";

import NavItem from "./NavItem";
const sections = [
  {
    items: [
      {
        title: "Progress",
        href: "/dashboard",
      },
      {
        title: "Resume history & templates",
        href: "/dashboard/resume-template",
      },
      {
        title: "FeedBack",
        href: "/dashboard/feedback",
      },
      {
        title: "My Account",
        href: "/dashboard/subscription",
      },
      {
        title: "Magic Plans",
        href: "/pricing",
      },
      {
        title: "Help",
        href: "/help",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 213,
    padding: "0 33px",
    top: 80,
    // height: "calc(100% - 80px)",
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 0px",
    },
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  paddingy:{
    padding:"32px 0",
    [theme.breakpoints.down("md")]:{
      padding:"15px 0 0",
    },
    [theme.breakpoints.down("sm")]:{
      padding:"0px 0 0",
    },
    [theme.breakpoints.down("xs")]:{
      padding:"0px 0 0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      display="flex"
      flexDirection="column"
      // style={{ backgroundColor: "#19194b" }}
    >
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          // style={{ backgroundColor: "#19194b" }}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box  className={classes.paddingy}>
              {sections.map((section, i) => (
                <List
                  className="sideMenu"
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};
export default NavBar;
