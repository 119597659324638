import React, { createContext, useState } from "react";

export const UserContext = createContext();

const setSession = (token, user, isRemember) => {
  if (token) {
    if (isRemember) {
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    }
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
};

function checkLogin() {
  const accessToken =
    window.localStorage.getItem("token") ||
    window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());

  let data = {
    userLoggedIn: isLogin,
    userLogIn: (status, token, user, isRemember) => {
      setSession(token, user, isRemember);
      setIsLogin(status);
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
