import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    marginBottom:"10px",
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]:{
      margin:"0 10px",
    },
  },
  button: {
    color: "#969ba1",
    padding: "10px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    // color: "#969ba1",
    padding: "10px 8px",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderRadius: 0,
    justifyContent: "center",
    fontSize: "16px",
    fontFamily: "Alegreya",
    fontStyle: "italic",
    position:"relative",
    lineHeight: "25px",
    color:" #e5e5e5 !important",
    fontWeight: "700 !important",
    height: "50px",
    borderRadius: "50px !important",
    zIndex:"2",
    [theme.breakpoints.down("md")]: {
      height: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      height: "40px",
    },
    "&:hover": {
      zIndex:"1",
    '& .MuiButton-label':{
      color: "#018582 !important",
      // background: "-webkit-linear-gradient(#01657b, #01cd92)",
      // WebkitBackgroundClip:" text",
      // WebkitTextFillColor: "transparent",
      // [theme.breakpoints.down('md')]:{
      //   background: "-webkit-linear-gradient(#01cd92 , #01657b)",
      // WebkitBackgroundClip:" text",
      // WebkitTextFillColor: "transparent",
      //   color: "#018582 !important",
      // },
      zIndex:"99",
    },
    '&::before':{
      content: '" "',
      display: 'block',
      width: "100%",
      height: "100%",
      borderRadius: 0,
      position:"absolute",
      borderRadius: "50px",
      left:"0",
      top:"0",
      background:"#fff",
      zIndex:"1",
    },
    },
    "&.active": {
    
    zIndex:"1",
    '& .MuiButton-label':{
      color: "#018582 !important",
      // background: "-webkit-linear-gradient(#01657b, #01cd92)",
      // WebkitBackgroundClip:" text",
      // WebkitTextFillColor: "transparent",
      // [theme.breakpoints.down('md')]:{
      //   background: "-webkit-linear-gradient(#01cd92 , #01657b)",
      // WebkitBackgroundClip:" text",
      // WebkitTextFillColor: "transparent",
      //   color: "#018582 !important",
      // },
      zIndex:"99",
    },
    '&::before':{
      content: '" "',
      display: 'block',
      width: "100%",
      height: "100%",
      borderRadius: 0,
      position:"absolute",
      borderRadius: "50px",
      left:"0",
      top:"0",
      background:"#fff",
      zIndex:"1",
    },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#fafafa",
    borderColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#fafafa",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        // disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName="active"
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {title}

      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
