import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import PriceLayout from "./layouts/PriceLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/signup",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/LogIn/SignUp")),
  },
  {
    exact: true,
    path: "/country",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/LogIn/Country")),
  },
  {
    exact: true,
    path: "/verifyOTP",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/LogIn/VerifyOTP")),
  },

  // {
  //   exact: true,
  //   path: "/login",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/auth/LogIn/Login")),
  // },
  {
    exact: true,
    path: "/forgot-password",
    layout: HomeLayout,
    component: lazy(() => import("src/views/auth/LogIn/ForgotPassword")),
  },
  {
    exact: true,
    path: "/testimonials",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Testimonials")),
  },

  {
    exact: true,
    path: "/Dashboardpage",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/DashboardPage1")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/dashboard/subscription",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/MyAccount")),
  },
  {
    exact: true,
    path: "/loding",
    component: lazy(() => import("src/component/PageLoading")),
  },
    

  {
    exact: true,
    path: "/dashboard/feedback",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/FeedBack")),
  },

  {
    exact: true,
    path: "/dashboard/resume",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/Resume")),
  },
  {
    exact: true,
    path: "/basic-resume",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/BasicResume")),
  },
  {
    exact: true,
    path: "/executive-resume",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/ExecutiveResume")),
  },
  {
    exact: true,
    path: "/college-resume",
    component: lazy(() => import("src/views/pages/Dashboard/CollegeResume")),
  },
  {
    exact: true,
    path: "/dashboard/resume-template",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ResumeTemplet")),
  },
  {
    exact: true,
    path: "/res",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Resumes")),
  },

  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Desclaimer")),
  },

  {
    exact: true,
    path: "/cookies-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CookiePolicy")),
  },

  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/termsconditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Term&Condition")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Contactus")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/product",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/Home/Product")),
  },
  {
    exact: true,
    path: "/pricing",
    layout: PriceLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Home/Pricing")),
  },
  {
    exact: true,
    path: "/help",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/component/Help")),
  },
  {
    exact: true,
    path: "/humanservice",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/HumanService")),
  },
  {
    exact: true,
    path: "/helpChat",
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/HelpChat")),
  },
  {
    exact: true,
    path: "/jobfit",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/JobFit")),
  },

  {
    exact: true,
    path: "/scoring",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/Scoring")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
