import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  fade,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Modal,
  Fade,
  Backdrop,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
import { UserContext } from "src/context/User";
import axios from "axios";
import { baseApiUrl } from "src/constants";
const headersData = [
  {
    label: "Home Page",
    href: "/",
  },
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Feedback",
    href: "/dashboard/feedback",
  },
  // {
  //   label: localStorage.getItem("user")
  //     ? JSON.parse(localStorage.getItem("user"))?.user_management?.name
  //     : " ",
  //   href: "/dashboard/subscription",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    fontWeight: "400",
    borderRadius: 0,
    marginLeft: "16px",
    minWidth: "auto",
    color: "#fffff",
    padding: "10px 24px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      padding: "18px 16px !important",
      fontSize: "15px !important",
      height: "80px",
      marginLeft: "0 !important",
    },
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
    "&:active": {
      color: "#1ed760",
    },
    // "&:last-child": {
    //   backgroundColor: "#1ed760",
    // },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "80%",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
  },
  drawericon: {
    color: "#000",
    padding: 0,

    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "36px",
    // marginTop: "7px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    width: "160px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "257px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px",
  },
  paper: {
    backgroundColor: "black",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "350px",
    boxShadow: "0px 0px 8px -1px #35e397",
    textAlign: "center",
    "@media(max-width:748px)": {
      width: "auto",
    },
  },
  checkcenter: {
    paddingTop: "20px",
    paddingBottom: "20px",
    "@media(max-width:767px)": {
      padding: "20px",
    },
    "& .button1": {
      width: "fit-content",
    },
  },
}));

export default function Header() {
  const token = window.localStorage.getItem("token");
  const {
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,

    logoDrawer,
    containerHeight,

    mainHeader,
  } = useStyles();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [isuser, setUser] = React.useState({});
  const anchorRef = React.useRef(null);
  const user = useContext(UserContext);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "JWT " + localStorage.getItem("token"),
  };
  const LogoutAccount = () => {
    try {
      axios({
        method: "GET",
        url: `${baseApiUrl}/user-management/logout/`,
        data: null,
        headers,
      })
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            user.userLogIn(false, null, null, null);
            handleClose(false);
            history.push("/login");
          }
        })
        .catch((err) => {
          if (
            err.response.data.detail === "Invalid signature." ||
            err.response.status === 401
          ) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            history.push("/login");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    setUser(() => JSON.parse(window.localStorage.getItem("user")));
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, [token]);

  const displayDesktop = () => {
    return (
      <Container maxWidth="xl">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}
            <Button
              color="inherit"
              className={menuButton}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              // onClick={() => {
              //   history.push("/dashboard");
              // }}
            >
              {isuser ? isuser?.user_management?.name : ""}
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          style={{
                            padding: "0px 44px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) => {
                            handleClose(e);
                            handleOpen1();
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              pt={7}
              open={open1}
              // onClose={handleClose1}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open1}>
                <Box className={classes.paper}>
                  <div className={classes.checkcenter}>
                    <Typography variant="h4">Are you sure to logout</Typography>
                    <Typography variant="h4">your Account ?</Typography>
                    <Box
                      pt={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={LogoutAccount}
                        className="button1 colorfix"
                      >
                        <Typography className="textcolor">Yes</Typography>
                      </Button>
                      <Button
                        onClick={handleClose1}
                        className="button1 bottonbgcolor"
                      >
                        <Typography className="textcolor ">No</Typography>
                      </Button>
                    </Box>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {getDrawerChoices()}
            <Button
              color="inherit"
              className={menuButton}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              // onClick={() => {
              //   history.push("/dashboard");
              // }}
            >
              {isuser ? isuser?.user_management?.name : ""}
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          style={{
                            padding: "0px 44px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) => {
                            handleClose(e);
                            handleOpen1();
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              pt={7}
              open={open1}
              // onClose={handleClose1}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open1}>
                <Box className={classes.paper}>
                  <div className={classes.checkcenter}>
                    <Typography variant="h4">Are you sure to logout</Typography>
                    <Typography variant="h4">your Account ?</Typography>
                    <Box
                      pt={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={LogoutAccount}
                        className="button1 colorfix"
                      >
                        <Typography className="textcolor">Yes</Typography>
                      </Button>
                      <Button
                        onClick={handleClose1}
                        className="button1 bottonbgcolor"
                      >
                        <Typography className="textcolor ">No</Typography>
                      </Button>
                    </Box>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} align="right">
            <IconButton
              className={drawericon}
              {...{
                // edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Link>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link href="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={
          history.location.pathname !== "/"
            ? { backgroundColor: "rgb(0 6 13)" }
            : { backgroundColor: "rgb(0 6 13)" }
        }
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "xl" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
